.Scheduler {
	box-shadow: none !important;
	.MuiToolbar-root {
		border: 0;
		margin: 10px 0px;
	}
	&.week {
		.MuiToolbar-root {
			margin: 0 60px 10px;
		}
	}

	&.week, &.month {
		td {
			border-color: #dde4fc;
			&:last-child{
				border-right: 1px solid #dde4fc;
			}
		}
		&.month {
			td {
				&:first-child {
					border-left: 1px solid #dde4fc;
				}
			}
			> div > div > div {
			 	> :first-child {
					td {
						border: 0;
					}	
			 	}
			}
		}
		> div {
			box-shadow: none;
			 > div > div {
			 	> :first-child {
					> div > div {
						border: 0;
					}
					table {
						td {
							padding: 0;
							> div {
								padding: 10px 0;
								background: #f4f6ff;
								border: 1px solid #dde4fc;
								border-radius: 20px 20px 0 0;
							}
							&:last-child{
								border-right: none;
							}
						}
					}
			 	}
			 	> :nth-child(2) {
			 		> :first-child {
				 		table {
							td {
								&:last-child{
									border-right: none;
								}
							}
						}
			 		} 
			 	}
			}
		}
	}
  [class*="makeStyles-ordinaryHeaderBorder"] {
    [class*="AllDayContainer-container"] {
      border-bottom: 1px solid #ccc;
    }
  }
  .MuiBackdrop-root {
    position: fixed !important;
    height: 100vh;
    width: 100vw;
    top: 0;
    background-color: #3f51b5;
    opacity: .6 !important;
    pointer-events: none;
  }
  .MuiDrawer-paper {
    bottom: 0;
    width: calc(100vw / 1.5);
    height: 520px;
    top: auto;
    position: fixed !important;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 20px 20px 0 0;
    overflow-x: hidden;
    > div {
      width: 100%;
      > :first-child, & [class*="stickyContainer"] {
        display: block;
        position:absolute;
        width: calc(100% + 1px);
        &:before {
          content: 'Calendar item';
          display: block;
          background-color: #2a7fd7;
          color: #fff;
          padding: 12px 40px;
          font-size: 18px;
          font-weight: 400;
        }
      } 
      > :nth-child(2) {
          padding-top: 50px;
          padding-bottom: 60px;  
        > div > :nth-child(4) {
          display: none;
          span {
            color: #606060 !important;
          }
        }
      }
      > div > div {
        width: 100%;
        &.MuiGrid-root {
          > button {
            position: absolute;
            right: 0;
            width: 40px;
            height: 40px;
            top: 2px;
            color: #fff;
            svg {
              font-size: 16px;
            }
            &.MuiIconButton-root:nth-child(2) {
              display: none;
            }
          }
          .MuiButton-root { 
            top: 450px;
            right: 30px;
            width: 150px;
          }
        }
        > p {
          display: none; 
        }
        > div {
          margin-top: 5px;
          &[class*="LayoutBase-line"] {
            display: none;
          }
          > .MuiFormControl-root {
            width: 49%;
            margin-top: 0;
          } 
          > .MuiFormControl-fullWidth {
            width: 100%;
          }
          > p {
            width: 2%;
          }
        }
        fieldset {
          border: 0;
        }
        .MuiFormControlLabel-root {
          margin-left: -8px;
        }
        .MuiInputBase-root {
          background: #fff;
          border: 1px solid #dde4fc;
          border-radius: 5px;
          font-weight: 400;
          font-size: 16px;
          margin-top: 5px;
          border-bottom:1px solid #b5bede;
        }
        .MuiFilledInput-underline:before {
          border-bottom-color:transparent;
        }
      }
    }
  }
}

// CalendarTooltip
.MuiPaper-root.MuiPopover-paper {
  [class*="Header-head"] {
    background-color: #2a7fd7;
    margin-bottom: 10px;
    > * {
      color: #fff;
    }
  }
  
}