.#{$DRAFTAIL}ToolbarGroup {
  display: inline-block;

  & + & {
    &::before {
      content: "";
      display: inline-block;
      width: 1px;
      height: 1rem;
      vertical-align: middle;
      margin: 0 $controls-spacing / 2;
      background-color: $draftail-editor-chrome-accent;
    }
  }

  .#{$DRAFTAIL}Editor--readonly & {
    opacity: $draftail-editor-readonly-opacity;
  }
}
