.form-wrapper {
  z-index: 99;
  width: calc(50vw - 100px);
  bottom: 0;
  right: 65px;
  min-width: 500px;
  box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.3),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  position: fixed;
  background-color: #fff;
  max-height: calc(100vh - 180px);
  border-radius: 25px 25px 0 0px;
  &:before {
    content: '';
    background-color: rgba(4, 30, 122, 0.5);
    width: 100vw;
    height: 100vh;
    display: block;
    position: absolute;
    right: -65px;
    bottom: 0;
    z-index: -1;
  }
  > div {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 220px);
    padding: 10px;
    background-color: #fff;
    > button {
      position: absolute;
      top: 5px;
      right: 10px;
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      opacity: 0.8;
    }
    > form > * > button {
      padding: 8px;
      min-width: 160px;
      text-transform: none;
      background-color: #2a7fd7;
    }
  }
  > h2 {
    background-color: #2a7fd7;
    padding: 10px 25px;
    border-radius: 25px 25px 0 0;
    margin: 0;
    color: #fff;
    font-weight: 400;
    font-size: 25px;
  }

  &.-full {
    width: 50%;
    right: 25%;
    &:before {
      right: -50%;
    }
  }

  &.-wide {
    width: 80%;
    right: 8%;
    &:before {
      right: -10%;
    }
    > div {
      padding: 30px;
      min-height: 250px;
    }
  }
}
