body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 1025px) {
  body > #root {
    width: 100vw;
    // min-width: 100vw;
    display: flex;
  }
}

@media (max-width: 890px) {

  .App > div {
    background-color: #fff;
  }
  main > .MuiBox-root {
    flex-direction: column;
  }

  .MuiContainer-root {
    max-width: calc(100vw - 60px);
  }

  main > main.MuiContainer-root, main > div > main.MuiContainer-root {
    padding: 10px !important;
    font-size: .7rem;
    margin: 0;
  }

  .MuiContainer-root  > .MuiBox-root {
    width: inherit;
    max-width: calc(100vw - 60px);
  }

  .route-content {
    width: 100vw;
  }


  .register-wrapper  {
    margin-top: -30px !important;
    max-width: 100vw !important;
    form {
      width: calc(100vw - 20px);
    }
    > form > .MuiGrid-container {
      .file {
        margin-left: 25px !important;
        > a > div {
          background: transparent;
        }
      }
    }

    .form-wrapper {
      min-width: 100vw !important;
      right: 0 !important;
      &::before {
        right: 0 !important;
      }
      > div > div {
        padding: 20px 0px 100px;
        > button {
          margin-top: -200px;
          font-size: 10px;      
        }
      }
    }
  }

  .upload-wrapper {
    padding-left: 0 !important;
    > div {
      max-width: calc(100vw - 30px);
    }
  }


  .route-content > main form {
    max-width: calc(100vw - 20px);
    margin-bottom: 20px;
    > .MuiGrid-container {
      &:not(:nth-child(0)) {
        &:not(:nth-child(1)),
        &:not(:nth-child(2)) {
          & > .MuiGrid-container::before {
            content: '';
            height: 2px;
            width: 100%;
            text-align: center;
            margin: 10px auto 20px;
            display: block;
            background: #fff;
            border-top: 1px solid rgba(63,81,181, .4)
          }
        }
      }
      
      
      > * {
        margin: 5px !important;
        &.MuiTypography-h5 {
          margin: 50px 25px 0px 5px !important;
        }
      }
      > p {
        font-size: 1.2em;
        margin-bottom: 30px !important;
      }
    }
    h6.MuiTypography-root {
      flex: 0;
    }
  }


  .MuiContainer-root .MuiTypography-h2{
    font-size: 1.7rem;
  }

  .MuiContainer-maxWidthLg .MuiList-padding {
    flex-direction: column;
    min-width: 220px;
  }

  .MuiGrid-grid-xs-6{
    max-width: 100vw !important;
    flex-basis: 100vw !important;
  }

  .MuiGrid-grid-xs-6 * {
    max-width: 100vw !important;
  }

  .MuiGrid-grid-xs-6 > * {
    width: 100% !important;
  }

  table {
    border: 0 !important;
    max-width: 100vw;
  }
  table thead {
    display: none !important;
  }
  table tr {
    display: block !important;
    margin-bottom: 10px !important;
    width: 100% !important;
    border: 1px solid;
    border-color: #B5BEC0;
    border-radius: 15px;
  }
  table td {
    width: 100% !important;
    min-height: 35px;
  }
  table td {
    border-bottom: 1px dotted #ccc !important;
    display: block !important;
    font-size: 13px !important;
    text-align: right !important;
  }
  table td:last-child {
    border-bottom: 0 !important;
  }
  table td:before {
    content: attr(data-label) !important;
    float: left !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
  }
  
  .MuiGrid-root.MuiGrid-item {
    .MuiButtonBase-root.MuiChip-root.MuiChip-clickable {
      position: relative;
      top: 15px;
      right: -20px;
      .MuiChip-label {
        padding: 0 !important;
        font-size: 11px;
      }
    }
  }

  .MuiAutocomplete-inputRoot[class*=MuiInput-root] {
    margin-top: 3px;
  }
  .MuiAutocomplete-inputRoot[class*=MuiInput-root] .MuiAutocomplete-input:first-child {
    padding: 12px 0 !important;
  }

  .MuiPaper-root:nth-child(1) {
    .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled {
      max-width: 140px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .MuiTabs-root {
    .MuiTabs-scroller.MuiTabs-fixed {
      overflow: scroll !important;
      width: calc(100vw - 190px);
    }
  }
}
