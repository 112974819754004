@import "draftail/lib/index";

:root {
  --animationHeight: 112px;
}

@keyframes open {
  from { 
    opacity: 0;
    height: 0;
  }
  to { 
    opacity: 1;
    height: var(--animationHeight) 
  }
}

 
.RichEditor-Wrapper {
  width: 100%;
  &.error {
    .Draftail-Editor {
      border-color: #f50057;
    }
  }
  .Draftail-Editor {
    position: relative;
    border: 1px solid #dadff3;
    border-bottom: 1px solid #b3bee6;
    border-radius: 4px;
    .DraftEditor-editorContainer > div {
      min-height: 250px;
    }
    &.Draftail-Editor--focus {
      > .Draftail-Toolbar {
        position: relative;
      }
    }
    .ImageBlockWrapper {
      position: relative;
      width: fit-content;
      height: auto;
      padding: 0;
      margin: 0;
      line-height: 0;
      > ul {
        position: absolute;
        top: 0;
        bottom: 0;
        padding: 0;
        left: 0;
        right: 0;
        margin: 0;
        list-style: none;
        li {
          height: 7px;
          width: 7px;
          background-color: #fff;
          position: absolute;
          z-index: 1;
          border: 1px solid #7a7a7a;
          &:nth-child(1) {
            top: 0;
            left: 0;
          }
          &:nth-child(2) {
            left: 50%;
            top: 0;
          }
          &:nth-child(3) {
            right: 0;
            top: 0;
          }
          &:nth-child(4) {
            right: 0;
            top: 50%;
          }
          &:nth-child(5) {
            right: 0;
            bottom: 0;
          }
          &:nth-child(6) {
            right: 50%;
            bottom: 0;
          }
          &:nth-child(7) {
            left: 0;
            bottom: 0;
          }
          &:nth-child(8) {
            left: 0;
            bottom: 50%;
          }
        }
      }
    }
    .Draftail-Toolbar {
      background-color: #eff1fa;
      border-radius: 4px;
      color: #031e82;
      border-bottom-color: #b5bede;
      > .Draftail-ToolbarGroup {
        &:before {
          content: none;
        }
        > button {
          font-weight: 100;
          font-size: 16px;
          padding: 7px;
          padding-bottom: 10px;
          fill: #031e82;
          color: #031e82;
          margin-right: 2px;
          &:hover {
            fill: #000;
            color: #000;
          }
          > svg {
            width: 20px;
          }
          &.Draftail-ToolbarButton--active, &:hover {
            background-color: #fff;
            border: 1px solid #dddddd;
            border-radius: 20%;
          }
        }
        .Draftail-ToolbarButton__label {
          width: 20px;
        }
      }
    }
    .DraftEditor-ImageSource, .DraftEditor-LinkSource {
      overflow: hidden;
      --animationHeight: 112px;
      animation: .2s ease-out 0s 1 open;
      background-color: rgba(239, 241, 250, .7);
      position: absolute;
      top: 80px;
      left: 88px;
      width: 400px;
      border: 1px solid #dadff3;
      pointer-events: all;
      padding: 10px;
      &.error {
        > .message {
          color: #ff3333;
          padding: 0px 0px 5px 5px;
        }
        > input {
          border: 1px solid #fbbfc2;        
        }
      }
      > input {
        height: 45px;
        border-radius: 4px;
        border: 1px solid #dadff3;
        padding-left: 10px;
        width: 100%;
        &:focus {
          outline: none;
          border-color: #b3bee6;
        }
      }
      > button {
        margin-top: 10px;
        height: 35px;
        color: #fff;
        background-color: #3f51b5;
        border: 0;
        border-radius: 4px;
        padding: 0 15px;
        cursor: pointer;
        &.cancel {
          float: right;
          border: 1px solid #eceef9;
          border-radius: 40%;
          width: 35px;
          height: 35px;
          padding: 0;
          background-color: white;
          color: #606060;
          &:hover {
            color: #000;
            background-color: #f8f8f8;
          }
        }
      }
    }
  }
}
