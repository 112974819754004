// Namespace for all classes, reduces the risk of style clashes.
$DRAFTAIL: "Draftail-";

// Overridable variables meant to be used for theming, and easier integration.

$draftail-base-spacing: 0.25rem !default;

$button-spacing: $draftail-base-spacing * 2;
$controls-spacing: $draftail-base-spacing;
$draftail-editor-padding: $button-spacing + $controls-spacing !default;

$color-white: #fff;
$color-black: #000;
$color-grey: #333;
// Default Draft.js placeholder text color, minus 15% lightness.
$color-grey-525760: #525760;
$color-light-grey: #ddd;

$draftail-editor-text: $color-grey !default;
$draftail-editor-background: $color-white !default;
$draftail-editor-readonly-opacity: 0.5 !default;
$draftail-editor-chrome: $color-light-grey !default;
$draftail-editor-chrome-text: $color-grey !default;
$draftail-editor-chrome-active: $color-black !default;
$draftail-editor-chrome-accent: darken(
  $color: $draftail-editor-chrome,
  $amount: 10%,
) !default;
$draftail-tooltip-chrome: $color-grey !default;
$draftail-tooltip-chrome-text: $color-white !default;

$draftail-editor-font-family: sans-serif !default;
$draftail-editor-font-size: 1rem !default;
$draftail-editor-line-height: 1.5 !default;

$draftail-placeholder-text: $color-grey-525760 !default;

$draftail-editor-border: 1px solid $draftail-editor-chrome !default;

$draftail-editor-radius: 5px !default;
$draftail-tooltip-radius: 5px !default;
$draftail-toolbar-radius: 0 !default;
$draftail-toolbar-tooltip-radius: 4px !default;
$draftail-toolbar-border-bottom: $draftail-editor-border !default;

$draftail-toolbar-tooltip-duration: 0.1s !default;
$draftail-toolbar-tooltip-delay: 1s !default;

$draftail-block-spacing: $draftail-base-spacing * 2 !default;

$draftail-editor-z-index: 1 !default;
$draftail-tooltip-z-index: $draftail-editor-z-index + 1 !default;
$draftail-overlay-z-index: $draftail-tooltip-z-index + 1 !default;
$draftail-toolbar-z-index: $draftail-overlay-z-index + 1 !default;
$draftail-toolbar-tooltip-z-index: $draftail-toolbar-z-index + 1 !default;

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
