.#{$DRAFTAIL}Editor {
  background-color: $draftail-editor-background;
  border: $draftail-editor-border;
  border-radius: $draftail-editor-radius;

  &--hide-placeholder .public-DraftEditorPlaceholder-root {
    display: none;
  }

  &--readonly {
    pointer-events: none;

    .DraftEditor-editorContainer {
      opacity: $draftail-editor-readonly-opacity;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: $draftail-overlay-z-index;
      }
    }
  }

  .DraftEditor-root {
    color: $draftail-editor-text;
    font-size: $draftail-editor-font-size;
    line-height: $draftail-editor-line-height;
    font-family: $draftail-editor-font-family;
    // Ligatures can make cursor behavior harder to understand.
    font-variant-ligatures: none;
    // Fix editor scrolling in the wrong position when breaking a big block.
    // See https://github.com/facebook/draft-js/issues/304#issuecomment-327606596.
    overflow: auto;
  }

  .public-DraftEditor-content,
  .public-DraftEditorPlaceholder-root {
    padding: $draftail-editor-padding;
  }

  // Remove default margins on atomic blocks because of the figure element.
  .public-DraftEditor-content > * > figure {
    margin: 0;
  }

  .public-DraftEditorPlaceholder-inner {
    color: $draftail-placeholder-text;
  }
}

// Give each block some spacing so we don't end up with empty paragraphs
// in code when user double enters because they think there are no paragraphs.
// Also add the same styles to the placeholder for alignment.
.#{$DRAFTAIL}block--unstyled,
.#{$DRAFTAIL}Editor .public-DraftEditorPlaceholder-inner {
  margin: $draftail-block-spacing 0;
}

// This makes it possible to add styles to rich text content within the editor,
// without Draftail users having to know exactly which Draft.js class to use.
@mixin draftail-richtext-styles {
  .#{$DRAFTAIL}Editor .DraftEditor-editorContainer {
    @content;
  }
}
