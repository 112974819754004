.#{$DRAFTAIL}Toolbar {
  position: relative;
  padding: $controls-spacing;
  background-color: $draftail-editor-chrome;
  color: $draftail-editor-chrome-text;
  border-radius: $draftail-toolbar-radius;
  border-bottom: $draftail-toolbar-border-bottom;

  .#{$DRAFTAIL}Editor--focus & {
    // Browsers that do not support this will simply not have this added UI.
    // stylelint-disable-next-line plugin/no-unsupported-browser-features
    position: sticky;
    top: 0;
    z-index: $draftail-toolbar-z-index;
  }
}
