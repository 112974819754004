.App {
	min-height: 100vh;
  background: #f7f7f7;
  width: 100%;
	main > h1 {
		font-weight: 500;
    font-size: 35px;
		margin: 40px 5px 20px;
	}

	// custom deselect behavior for select component

  // custom margins and width for main containers
  main.MuiContainer-maxWidthLg{
    width: auto;
    max-width: initial;
    padding: 0 40px;
  }

  code {
    padding: 0.2em 0.3125em;
    margin: 0px;
    font-size: 85%;
    background-color: rgba(27, 31, 35, 0.05);
    font-family: "Poppins",  Consolas, Menlo, Monaco, "Lucida Console", "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", monospace, sans-serif;
    border-radius: 3px;
  }

  p {
    margin: .2em 0;
  }
}

.MuiPopover-root li[data-value=''] {
  opacity: .5;
}
