.DatePicker {
	cursor: pointer;
	&:not(.Mui-error) > div {
		border: 1px solid #dadff3;
		border-bottom-color: #b3bee6;
	  padding: 11px 10px;
	  border-radius: 4px;
	  margin-top: 0 !important;
	  cursor: pointer;
	  * {
	  	cursor: pointer;
	  }
	  &:before, &:after {
	    border: none !important;
	  }
	  &.Mui-error {
	  	color: #f44336;
	  	border-color: #f44336;
	  }
	  &.Mui-disabled {
	  	border-color: #bdbdbd;
	  }
	}
  > p.Mui-error {
  	margin-left: 14px;
  	margin-right: 14px;
  }
  &:hover > div {
  	border-color: #303f9f
  }
	> label {
		transform: translate(14px, -6px) scale(0.75);
    background-color: #f0f3f5;
    z-index: 9;
    padding: 0 10px;
    left: -7px;
	}
}